import { BuildingUse } from "../../../../../../../types/BuildingUse";
import { Config } from "../../../../../../../types/Config";
import Format from "../../../../../../../types/Format";
import { VariableId } from "../../../../../../../types/VariableId";

const config: Config = {
  /**
   * Back Of the Envelope Panel.
   */

  [VariableId.ProjectTotalDevelopmentCost]: {
    variableId: VariableId.ProjectTotalDevelopmentCost,
    requiresAuthentication: true,
    text: "Total Project Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  // <Gross Income>

  [VariableId.MultifamilyYearOnePotentialGrossIncome]: {
    variableId: VariableId.MultifamilyYearOnePotentialGrossIncome,
    requiresAuthentication: true,
    buildingUse: BuildingUse.Multifamily,
    text: "Multifamily",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.RetailYearOnePotentialGrossIncome]: {
    variableId: VariableId.RetailYearOnePotentialGrossIncome,
    requiresAuthentication: true,
    buildingUse: BuildingUse.Retail,
    text: "Retail",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.OfficeYearOnePotentialGrossIncome]: {
    variableId: VariableId.OfficeYearOnePotentialGrossIncome,
    requiresAuthentication: true,
    buildingUse: BuildingUse.Office,
    text: "Office",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.IndustrialYearOnePotentialGrossIncome]: {
    variableId: VariableId.IndustrialYearOnePotentialGrossIncome,
    requiresAuthentication: true,
    buildingUse: BuildingUse.Industrial,
    text: "Industrial",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.HotelAnnualPotentialRoomRevenueForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualPotentialRoomRevenueForBackOfEnvelope,
    requiresAuthentication: true,
    buildingUse: BuildingUse.Hotel,
    text: "Hotel Rooms",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.HotelAnnualPotentialOtherRevenueForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualPotentialOtherRevenueForBackOfEnvelope,
    requiresAuthentication: true,
    buildingUse: BuildingUse.Hotel,
    text: "Hotel Other Revenue",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.IncomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope,
    requiresAuthentication: true,
    text: "Total Income",
    formatOptions: { type: Format.Type.Currency },
  },

  // </ Gross Income>

  [VariableId.CondoGrossSales]: {
    variableId: VariableId.CondoGrossSales,
    requiresAuthentication: true,
    text: "Condo Gross Sales",
    formatOptions: { type: Format.Type.Currency },
    buildingUse: BuildingUse.Condo,
  },

  // <Vacancy>

  [VariableId.MultifamilyAnnualVacancyExpenseForBackOfEnvelope]: {
    variableId: VariableId.MultifamilyAnnualVacancyExpenseForBackOfEnvelope,
    requiresAuthentication: true,
    text: "Multifamily",
    buildingUse: BuildingUse.Multifamily,
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.RetailAnnualVacancyExpenseForBackOfEnvelope]: {
    variableId: VariableId.RetailAnnualVacancyExpenseForBackOfEnvelope,
    requiresAuthentication: true,
    text: "Retail",
    buildingUse: BuildingUse.Retail,
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.OfficeAnnualVacancyExpenseForBackOfEnvelope]: {
    variableId: VariableId.OfficeAnnualVacancyExpenseForBackOfEnvelope,
    requiresAuthentication: true,
    buildingUse: BuildingUse.Office,
    text: "Office",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.IndustrialAnnualVacancyExpenseForBackOfEnvelope]: {
    variableId: VariableId.IndustrialAnnualVacancyExpenseForBackOfEnvelope,
    requiresAuthentication: true,
    buildingUse: BuildingUse.Industrial,
    text: "Industrial",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.HotelAnnualRoomUnrealizedRevenueForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualRoomUnrealizedRevenueForBackOfEnvelope,
    requiresAuthentication: true,
    text: "Hotel Rooms",
    buildingUse: BuildingUse.Hotel,
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.HotelAnnualOtherUnrealizedRevenueForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualOtherUnrealizedRevenueForBackOfEnvelope,
    requiresAuthentication: true,
    text: "Hotel Other Revenue",
    buildingUse: BuildingUse.Hotel,
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.IncomeProducingUsesAnnualVacancyExpenseForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualVacancyExpenseForBackOfEnvelope,
    requiresAuthentication: true,
    text: "Total Vacancy",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    }
  },

  // </ Vacancy>

  // <Operating Expense>

  [VariableId.MultifamilyYearOneOperatingExpense]: {
    variableId: VariableId.MultifamilyYearOneOperatingExpense,
    requiresAuthentication: true,
    text: "Multifamily",
    buildingUse: BuildingUse.Multifamily,
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    }
  },

  [VariableId.RetailYearOneOperatingExpense]: {
    variableId: VariableId.RetailYearOneOperatingExpense,
    requiresAuthentication: true,
    text: "Retail",
    buildingUse: BuildingUse.Retail,
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    }
  },

  [VariableId.OfficeYearOneOperatingExpense]: {
    variableId: VariableId.OfficeYearOneOperatingExpense,
    requiresAuthentication: true,
    text: "Office",
    buildingUse: BuildingUse.Office,
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    }
  },

  [VariableId.IndustrialYearOneOperatingExpense]: {
    variableId: VariableId.IndustrialYearOneOperatingExpense,
    requiresAuthentication: true,
    text: "Industrial",
    buildingUse: BuildingUse.Industrial,
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    }
  },

  [VariableId.HotelAnnualRoomOperatingExpenseForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualRoomOperatingExpenseForBackOfEnvelope,
    requiresAuthentication: true,
    text: "Hotel Rooms",
    buildingUse: BuildingUse.Hotel,
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    },
  },

  [VariableId.HotelAnnualOtherOperatingExpenseForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualOtherOperatingExpenseForBackOfEnvelope,
    requiresAuthentication: true,
    text: "Hotel Other Revenue",
    buildingUse: BuildingUse.Hotel,
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    },
  },

  [VariableId.IncomeProducingUsesAnnualOperatingExpenseForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualOperatingExpenseForBackOfEnvelope,
    requiresAuthentication: true,
    text: "Total Operating Expense",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    },
  },

  [VariableId.ProjectAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.ProjectAnnualNetOperatingIncomeForBackOfEnvelope,
    requiresAuthentication: true,
    text: "NOI",
    formatOptions: {
      type: Format.Type.Currency,
    },
  },

  // </ Operating Expense>

  // <Sale>

  [VariableId.ExitCapRate]: {
    variableId: VariableId.ExitCapRate,
    requiresAuthentication: true,
    text: "Exit Cap Rate",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 1,
    },
  },

  [VariableId.IncomeProducingUsesSaleValueForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesSaleValueForBackOfEnvelope,
    requiresAuthentication: true,
    text: "Estimated Value",
    formatOptions: { type: Format.Type.Currency },
    buildingUse: BuildingUse.IncomeProducing,
  },

  [VariableId.TotalProjectSaleForBackOfEnvelope]: {
    variableId: VariableId.TotalProjectSaleForBackOfEnvelope,
    requiresAuthentication: true,
    text: "Total Project Sale",
    formatOptions: { type: Format.Type.Currency }
  },

  // </ Sale>

  // <Profit>

  [VariableId.ProjectSaleCommissionPercentage]: {
    variableId: VariableId.ProjectSaleCommissionPercentage,
    requiresAuthentication: true,
    text: "Sales Commission",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 1,
    }
  },

  [VariableId.TotalProjectSalesCommissions]: {
    variableId: VariableId.TotalProjectSalesCommissions,
    requiresAuthentication: true,
    text: "Sales Cost",
    formatOptions: {
      type: Format.Type.Currency,
    }
  },

  [VariableId.TotalProjectSaleProfitForBackOfEnvelope]: {
    variableId: VariableId.TotalProjectSaleProfitForBackOfEnvelope,
    requiresAuthentication: true,
    text: "Total Profit",
    formatOptions: {
      type: Format.Type.Currency,
    }
  },

  // </ Profit>

  [VariableId.SaleReturnOnInvestmentForBackOfEnvelope]: {
    variableId: VariableId.SaleReturnOnInvestmentForBackOfEnvelope,
    requiresAuthentication: true,
    text: "ROC",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 2,
    },
  },

  [VariableId.IncomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope,
    requiresAuthentication: true,
    text: "ROC",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 2,
    },
  },
};

export default config;
