import { Config } from "../../../../../../../types/Config";
import Unit from "../../../../../../../types/Unit";
import Format from "../../../../../../../types/Format";
import { VariableId } from "../../../../../../../types/VariableId";
import { BuildingUse } from "../../../../../../../types/BuildingUse";
import valueGetters from "../../../../../../../state/development/utils/valueGetters";

const config: Config = {

  /**
   * Assumptions Panel.
   */

  // <Property>

  [VariableId.ParcelArea]: {
    variableId: VariableId.ParcelArea,
    text: "Lot Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.ExistingStructureArea]: {
    variableId: VariableId.ExistingStructureArea,
    text: "Existing Building Assumption",
    formatOptions: { type: Format.Type.Number },
    requiresAuthentication: true,
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    },
    getter: valueGetters.forExistingBuildingAreaInAssumptions,
  },

  [VariableId.ParcelPurchasePricePerArea]: {
    variableId: VariableId.ParcelPurchasePricePerArea,
    requiresAuthentication: true,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
    [Unit.System.Imperial]: {
      text: "Purchase Price PSF",
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF", },
    },
    [Unit.System.Metric]: {
      text: "Purchase Price PSM (Per Square Meter)",
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²", },
    }
  },

  [VariableId.ParcelPurchasePrice]: {
    variableId: VariableId.ParcelPurchasePrice,
    requiresAuthentication: true,
    text: "Property Purchase Price",
    formatOptions: { type: Format.Type.Currency },
  },

  // </ Property>

  // <Building>

  [VariableId.FloorAreaRatio]: {
    variableId: VariableId.FloorAreaRatio,
    requiresAuthentication: true,
    text: "Floor Area Ratio",
    formatOptions: {
      decimalPlaces: 2,
      type: Format.Type.Number,
    },
  },

  [VariableId.FloorAreaRatioWithoutParking]: {
    variableId: VariableId.FloorAreaRatioWithoutParking,
    requiresAuthentication: true,
    text: "Floor Area Ratio Without Parking",
    formatOptions: {
      decimalPlaces: 2,
      type: Format.Type.Number,
    },
  },

  [VariableId.UnitsPerParcelArea]: {
    variableId: VariableId.UnitsPerParcelArea,
    requiresAuthentication: true,
    text: "Unit Density Ratio",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1,
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.Acres,
      unitIsInverse: true,
      formatOptions: { suffix: " units / acre" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.Hectares,
      unitIsInverse: true,
      formatOptions: { suffix: " units / hectare" },
    }
  },

  [VariableId.ProjectNumberOfFloors]: {
    variableId: VariableId.ProjectNumberOfFloors,
    requiresAuthentication: true,
    text: "Number of Floors",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " floors",
    },
  },

  [VariableId.BuildingHeight]: {
    variableId: VariableId.BuildingHeight,
    requiresAuthentication: true,
    text: "Building Height",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1,
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.Feet,
      formatOptions: { suffix: " feet" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.Meters,
      formatOptions: { suffix: " meters" }
    }
  },

  [VariableId.SiteCoveragePercentage]: {
    variableId: VariableId.SiteCoveragePercentage,
    requiresAuthentication: true,
    text: "Lot Coverage",
    formatOptions: {
      type: Format.Type.Percentage,
    }
  },

  [VariableId.SiteCoverageArea]: {
    variableId: VariableId.SiteCoverageArea,
    requiresAuthentication: true,
    text: "Building Footprint",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.OpenSpaceArea]: {
    variableId: VariableId.OpenSpaceArea,
    requiresAuthentication: true,
    text: "Open Space Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  // </ Building>

  // <Areas>

  [VariableId.MultifamilyTotalUnitQuantity]: {
    variableId: VariableId.MultifamilyTotalUnitQuantity,
    requiresAuthentication: true,
    text: "Multifamily Units",
    buildingUse: BuildingUse.Multifamily,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units",
    },
  },

  [VariableId.CondoTotalUnitQuantity]: {
    variableId: VariableId.CondoTotalUnitQuantity,
    requiresAuthentication: true,
    text: "Condo Units",
    buildingUse: BuildingUse.Condo,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units",
    },
  },

  [VariableId.RetailNetLeasableArea]: {
    variableId: VariableId.RetailNetLeasableArea,
    requiresAuthentication: true,
    text: "Retail Leasable Area",
    buildingUse: BuildingUse.Retail,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.OfficeGrossLeasableArea]: {
    variableId: VariableId.OfficeGrossLeasableArea,
    requiresAuthentication: true,
    text: "Office Leasable Area",
    buildingUse: BuildingUse.Office,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.IndustrialNetLeasableArea]: {
    variableId: VariableId.IndustrialNetLeasableArea,
    requiresAuthentication: true,
    text: "Industrial Leasable Area",
    buildingUse: BuildingUse.Industrial,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.HotelRoomQuantity]: {
    variableId: VariableId.HotelRoomQuantity,
    requiresAuthentication: true,
    text: "Hotel Units",
    buildingUse: BuildingUse.Hotel,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " keys",
    },
  },

  [VariableId.ParkingRequiredSpacesAfterReduction]: {
    variableId: VariableId.ParkingRequiredSpacesAfterReduction,
    requiresAuthentication: true,
    text: "Parking After Reduction",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " spaces",
    },
  },

  [VariableId.ParkingGrossBuildableArea]: {
    variableId: VariableId.ParkingGrossBuildableArea,
    requiresAuthentication: true,
    text: "Total Parking Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  // </ Areas>

  // <Economics>

  [VariableId.MultifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableArea]: {
    variableId: VariableId.MultifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableArea,
    requiresAuthentication: true,
    text: "Multifamily Monthly Rent",
    buildingUse: BuildingUse.Multifamily,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.MultifamilyAverageYearOnePotentialGrossYearlyRentalIncomePerLeasableArea]: {
    variableId: VariableId.MultifamilyAverageYearOnePotentialGrossYearlyRentalIncomePerLeasableArea,
    requiresAuthentication: true,
    text: "Multifamily Yearly Rent",
    buildingUse: BuildingUse.Multifamily,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.RetailYearOneRentPerArea]: {
    variableId: VariableId.RetailYearOneRentPerArea,
    requiresAuthentication: true,
    text: "Retail Lease",
    buildingUse: BuildingUse.Retail,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.OfficeYearOneRentPerArea]: {
    variableId: VariableId.OfficeYearOneRentPerArea,
    requiresAuthentication: true,
    text: "Office Lease",
    buildingUse: BuildingUse.Office,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.IndustrialYearOneRentPerArea]: {
    variableId: VariableId.IndustrialYearOneRentPerArea,
    requiresAuthentication: true,
    text: "Industrial Lease",
    buildingUse: BuildingUse.Industrial,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.HotelStabilizedAverageDailyRate]: {
    variableId: VariableId.HotelStabilizedAverageDailyRate,
    requiresAuthentication: true,
    text: "Hotel ADR",
    buildingUse: BuildingUse.Hotel,
    formatOptions: {
      type: Format.Type.Currency,
      suffix: " / key",
    },
  },

  [VariableId.HotelStabilizedOccupancyPercentage]: {
    variableId: VariableId.HotelStabilizedOccupancyPercentage,
    requiresAuthentication: true,
    buildingUse: BuildingUse.Hotel,
    text: "Hotel Occupancy",
    formatOptions: { type: Format.Type.Percentage },
  },

  [VariableId.HotelStabilizedRevenuePerAvailableRoom]: {
    variableId: VariableId.HotelStabilizedRevenuePerAvailableRoom,
    requiresAuthentication: true,
    text: "Hotel RevPAR",
    buildingUse: BuildingUse.Hotel,
    formatOptions: {
      type: Format.Type.Currency,
      suffix: " / key",
    },
  },

  [VariableId.HotelOtherRevenuePercentage]: {
    variableId: VariableId.HotelOtherRevenuePercentage,
    requiresAuthentication: true,
    buildingUse: BuildingUse.Hotel,
    text: "Hotel Other Rev %",
    formatOptions: { type: Format.Type.Percentage },
  },

  // </ Economics>

  // <Project Cost>

  [VariableId.ProjectGrossBuildableArea]: {
    variableId: VariableId.ProjectGrossBuildableArea,
    requiresAuthentication: true,
    text: "Project Gross Buildable",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.ExistingStructureDemolitionCost]: {
    variableId: VariableId.ExistingStructureDemolitionCost,
    requiresAuthentication: true,
    text: "Total Demolition Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ProjectHardCost]: {
    variableId: VariableId.ProjectHardCost,
    requiresAuthentication: true,
    text: "Total Hard Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ProjectSoftCost]: {
    variableId: VariableId.ProjectSoftCost,
    requiresAuthentication: true,
    text: "Total Soft Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ProjectContingencyCost]: {
    variableId: VariableId.ProjectContingencyCost,
    requiresAuthentication: true,
    text: "Contingencies Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ProjectConstructionCostIncludingDemolition]: {
    variableId: VariableId.ProjectConstructionCostIncludingDemolition,
    requiresAuthentication: true,
    text: "Total Construction Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ProjectConstructionCostIncludingDemolitionPerBuildableArea]: {
    variableId: VariableId.ProjectConstructionCostIncludingDemolitionPerBuildableArea,
    requiresAuthentication: true,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      text: "Avg All-In Cost PSF",
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      text: "Avg All-In Cost PSM",
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  // </ Project Cost>

  [VariableId.ProjectTotalDevelopmentCost]: {
    variableId: VariableId.ProjectTotalDevelopmentCost,
    requiresAuthentication: true,
    text: "Total Project Cost",
    formatOptions: { type: Format.Type.Currency },
  },
};

export default config;
