import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import developmentAccessors from "state/development/utils/developmentAccessors";
import { allTogglesDisabled } from "views/utils/uiToggleHelper";
import PdfContext from "../../PdfContext";
import StandardPage from "../sharedComponents/StandardPage";
import Header from "../sharedComponents/Header";
import Assumptions from "./Assumptions";
import BackOfEnvelope from "./BackOfEnvelope";

const styles = StyleSheet.create({
  content: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    flexShrink: 0,
    backgroundColor: "#E5EBED",
  },
  leftContainer: {
    width: "344px",
  },
  rightContainer: {
    display: "flex",
    flexGrow: 1,
    flexShrink: 0,
  },
});

const AnalysisPage = () => (
  <PdfContext.Consumer>
    {({ development }) => {
      let toggles = developmentAccessors.getBuildingUsageToggles(development);

      if (allTogglesDisabled(toggles)) return null;

      return (
        <StandardPage backgroundColor="#E5EBED">
          <View style={styles.content}>
            <View style={styles.leftContainer}>
              <Header />
              <Assumptions />
            </View>

            <View style={styles.rightContainer}>
              <BackOfEnvelope />
            </View>
          </View>
        </StandardPage>
      );
    }}
  </PdfContext.Consumer>
);

export default AnalysisPage;
