import React from "react";
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  section: {
    position: 'relative',
    height: '20px',
    width: '80%',
    borderBottom: 0.7,
    borderColor: '#3A4276',
    left: '20px',
    top: '30px',
    paddingRight: '50px',
    marginTop: 10,
    minWidth: '250px',
  },
  title: {
    fontFamily: 'Roboto Bold',
    color: '#3A4276',
    fontSize: 12,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
  },
});

interface Props {
  graphTitle?: string
}

const GraphTitle = (props: Props) => (
  <View style={styles.section} >
    <Text style={styles.title}> {props.graphTitle} </Text>
  </View>
);

export default GraphTitle;
