import React from "react";
import developmentAccessors from "../../../../../../../state/development/utils/developmentAccessors";
import { incomeProducingUsesIsEnabled } from "../../../../../../utils/uiToggleHelper";
import ConfigurationHandler from "../../../../../ConfigurationHandler";
import PdfContext from "../../../PdfContext";
import GroupHeaderRow from "../../sharedComponents/GroupHeaderRow";
import BoldBorderRow from "./BoldBorderRow";
import config from "./config";
import DottedBorderRow from "./DottedBorderRow";
import Section from "./Section";
import SolidBorderRow from "./SolidBorderRow";
import { View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    minHeight: 425,
    backgroundColor: "#FFFFFF",
    padding: "15px 21px 32px",
    width: "100%",
  },
  group: {
    marginTop: 10,
  },
});

const BackOfEnvelope = () => (
  <PdfContext.Consumer>
    {({ development, unitSystem, projectIsForSale }) => {
      let toggles = developmentAccessors.getBuildingUsageToggles(development);
      let shouldDisplayIncomeProducingUses = incomeProducingUsesIsEnabled(toggles);

      return (
        <View style={styles.container}>
          <Section>
            {shouldDisplayIncomeProducingUses && (
              <View style={styles.group}>
                <GroupHeaderRow text="Gross Income" />
                <ConfigurationHandler
                  config={config.multifamilyYearOnePotentialGrossIncome}
                  component={SolidBorderRow}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.retailYearOnePotentialGrossIncome}
                  component={DottedBorderRow}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.officeYearOnePotentialGrossIncome}
                  component={DottedBorderRow}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.industrialYearOnePotentialGrossIncome}
                  component={DottedBorderRow}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.hotelAnnualPotentialRoomRevenueForBackOfEnvelope}
                  component={DottedBorderRow}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.hotelAnnualPotentialOtherRevenueForBackOfEnvelope}
                  component={DottedBorderRow}
                  unitSystem={unitSystem}
                />
              </View>
            )}
            <ConfigurationHandler
              config={config.incomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope}
              component={BoldBorderRow}
              unitSystem={unitSystem}
            />
            <ConfigurationHandler config={config.condoGrossSales} component={BoldBorderRow} unitSystem={unitSystem} />

            {shouldDisplayIncomeProducingUses && (
              <>
                <View style={styles.group}>
                  <GroupHeaderRow text="Vacancy" />
                  <ConfigurationHandler
                    config={config.multifamilyAnnualVacancyExpenseForBackOfEnvelope}
                    component={SolidBorderRow}
                    unitSystem={unitSystem}
                  />
                  <ConfigurationHandler
                    config={config.retailAnnualVacancyExpenseForBackOfEnvelope}
                    component={DottedBorderRow}
                    unitSystem={unitSystem}
                  />
                  <ConfigurationHandler
                    config={config.officeAnnualVacancyExpenseForBackOfEnvelope}
                    component={DottedBorderRow}
                    unitSystem={unitSystem}
                  />
                  <ConfigurationHandler
                    config={config.industrialAnnualVacancyExpenseForBackOfEnvelope}
                    component={DottedBorderRow}
                    unitSystem={unitSystem}
                  />
                  <ConfigurationHandler
                    config={config.hotelAnnualRoomUnrealizedRevenueForBackOfEnvelope}
                    component={DottedBorderRow}
                    unitSystem={unitSystem}
                  />
                  <ConfigurationHandler
                    config={config.hotelAnnualOtherUnrealizedRevenueForBackOfEnvelope}
                    component={DottedBorderRow}
                    unitSystem={unitSystem}
                  />
                  <ConfigurationHandler
                    config={config.incomeProducingUsesAnnualVacancyExpenseForBackOfEnvelope}
                    component={BoldBorderRow}
                    unitSystem={unitSystem}
                  />
                </View>

                <View style={styles.group}>
                  <GroupHeaderRow text="Operating Expense" />
                  <ConfigurationHandler
                    config={config.multifamilyYearOneOperatingExpense}
                    component={SolidBorderRow}
                    unitSystem={unitSystem}
                  />
                  <ConfigurationHandler
                    config={config.retailYearOneOperatingExpense}
                    component={DottedBorderRow}
                    unitSystem={unitSystem}
                  />
                  <ConfigurationHandler
                    config={config.officeYearOneOperatingExpense}
                    component={DottedBorderRow}
                    unitSystem={unitSystem}
                  />
                  <ConfigurationHandler
                    config={config.industrialYearOneOperatingExpense}
                    component={DottedBorderRow}
                    unitSystem={unitSystem}
                  />
                  <ConfigurationHandler
                    config={config.hotelAnnualRoomOperatingExpenseForBackOfEnvelope}
                    component={DottedBorderRow}
                    unitSystem={unitSystem}
                  />
                  <ConfigurationHandler
                    config={config.hotelAnnualOtherOperatingExpenseForBackOfEnvelope}
                    component={DottedBorderRow}
                    unitSystem={unitSystem}
                  />
                  <ConfigurationHandler
                    config={config.incomeProducingUsesAnnualOperatingExpenseForBackOfEnvelope}
                    component={BoldBorderRow}
                    unitSystem={unitSystem}
                  />
                  <ConfigurationHandler
                    config={config.projectAnnualNetOperatingIncomeForBackOfEnvelope}
                    component={BoldBorderRow}
                    unitSystem={unitSystem}
                  />
                  {!projectIsForSale && (
                    <ConfigurationHandler
                      config={config.projectTotalDevelopmentCost}
                      component={BoldBorderRow}
                      unitSystem={unitSystem}
                    />
                  )}
                </View>
              </>
            )}

            {projectIsForSale && (
              <View style={styles.group}>
                <GroupHeaderRow text="Sale" />
                <ConfigurationHandler config={config.exitCapRate} component={SolidBorderRow} unitSystem={unitSystem} />
                <ConfigurationHandler
                  config={config.incomeProducingUsesSaleValueForBackOfEnvelope}
                  component={DottedBorderRow}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.condoGrossSales}
                  component={DottedBorderRow}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.totalProjectSaleForBackOfEnvelope}
                  component={BoldBorderRow}
                  unitSystem={unitSystem}
                />
              </View>
            )}

            {projectIsForSale && (
              <View style={styles.group}>
                <GroupHeaderRow text="Profit" />
                <ConfigurationHandler
                  config={config.projectSaleCommissionPercentage}
                  component={SolidBorderRow}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.totalProjectSalesCommissions}
                  component={DottedBorderRow}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.projectTotalDevelopmentCost}
                  component={DottedBorderRow}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.totalProjectSaleProfitForBackOfEnvelope}
                  component={BoldBorderRow}
                  unitSystem={unitSystem}
                />
              </View>
            )}

            {projectIsForSale ? (
              <ConfigurationHandler
                config={config.saleReturnOnInvestmentForBackOfEnvelope}
                component={BoldBorderRow}
                unitSystem={unitSystem}
              />
            ) : (
              <ConfigurationHandler
                config={config.incomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope}
                component={BoldBorderRow}
                unitSystem={unitSystem}
              />
            )}
          </Section>
        </View>
      );
    }}
  </PdfContext.Consumer>
);

export default BackOfEnvelope;
