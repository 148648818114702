import React from "react";
import { View, StyleSheet, Image } from "@react-pdf/renderer";
import { GraphId } from "types/Graph";
import { GRAPH_TEXT_SETTINGS } from "utils/graphHelper";
import PdfContext from "../../PdfContext";
import StandardPage from "../sharedComponents/StandardPage";
import FullWidthHeader from "../sharedComponents/FullWidthHeader";
import GraphTitle from "./GraphTitle";

const PAGE_TITLE = "Demographic Trends";

const styles = StyleSheet.create({
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    height: 724,
    paddingBottom: 20,
  },
  row: {
    height: "50%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  whiteBox: {
    height: "100%",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 5,
    paddingTop: 0,
  },
  greyBox: {
    height: "100%",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#E5EBED",
    paddingLeft: 5,
    paddingTop: 0,
  },
  legendNote: {
    fontFamily: "Roboto",
    color: "#999999",
    borderTop: 0.5,
    borderColor: "#999999",
    width: "83%",
    left: "20px",
    fontSize: 9,
    marginTop: "45px",
    paddingTop: "5px",
    paddingLeft: 6,
  },
  image: {
    width: 250,
    height: 250,
    left: 20,
    top: 50,
  },
});

const GraphsPage = () => (
  <PdfContext.Consumer>
    {({ graphImages }) => {
      // If there are no graph images, we don't include this page in the PDF.
      if (graphImages === undefined) return null;

      return (
        <StandardPage>
          <FullWidthHeader pageTitle={PAGE_TITLE} />
          <View style={styles.contentContainer}>
            <View style={styles.row}>
              <View style={styles.whiteBox}>
                <GraphTitle graphTitle={GRAPH_TEXT_SETTINGS[GraphId.Population].title} />
                {graphImages[GraphId.Population] && (
                  <Image style={styles.image} src={graphImages[GraphId.Population]} />
                )}
              </View>
              <View style={styles.greyBox}>
                <GraphTitle graphTitle={GRAPH_TEXT_SETTINGS[GraphId.MedianGrossRent].title} />
                {graphImages[GraphId.MedianGrossRent] && (
                  <Image style={styles.image} src={graphImages[GraphId.MedianGrossRent]} />
                )}
              </View>
            </View>

            <View style={styles.row}>
              <View style={styles.greyBox}>
                <GraphTitle graphTitle={GRAPH_TEXT_SETTINGS[GraphId.EmploymentPerPopulation].title} />
                {graphImages[GraphId.EmploymentPerPopulation] && (
                  <Image style={styles.image} src={graphImages[GraphId.EmploymentPerPopulation]} />
                )}
              </View>
              <View style={styles.whiteBox}>
                <GraphTitle graphTitle={GRAPH_TEXT_SETTINGS[GraphId.MedianHouseholdIncome].title} />
                {graphImages[GraphId.MedianHouseholdIncome] && (
                  <Image style={styles.image} src={graphImages[GraphId.MedianHouseholdIncome]} />
                )}
              </View>
            </View>
          </View>
        </StandardPage>
      );
    }}
  </PdfContext.Consumer>
);

export default GraphsPage;
