import React from "react";
import AddressBar from "../AddressBar";
import PdfContext from "../../../PdfContext";
import PageTitle from "../PageTitle";
import { View, StyleSheet } from "@react-pdf/renderer";

const LEFT_PADDING = 20;

const styles = StyleSheet.create({
  container: {
    height: 40,
    paddingHorizontal: LEFT_PADDING,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
  },
});

interface Props {
  leftPadding?: number;
  pageTitle?: string;
}

const FullWidthHeader = (props: Props) => (
  <PdfContext.Consumer>
    {({ colorPalette }) => (
      <View style={[styles.container, { backgroundColor: colorPalette.primary }]} fixed>
        <PageTitle pageTitle={props.pageTitle} />
        <AddressBar textColor={colorPalette.text} />
      </View>
    )}
  </PdfContext.Consumer>
);

export default FullWidthHeader;
