import React from "react";
import BaseRow from "../../../sharedComponents/BaseRow";

const BORDER_WIDTH = 0.48;
const FONT_SIZE = 8.16;
const MIN_HEIGHT = 10;
const PADDING_TOP_BOTTOM = 2.5;

const Row = (props) => {
   props = {
    ...props,
    borderBottom: BORDER_WIDTH,
    borderColor: "#B7BBE3",
    borderStyle: "solid",
    labelFontFamily: "Roboto Condensed",
    valueFontFamily: "Roboto Condensed Bold",
    fontSize: FONT_SIZE,
    minHeight: MIN_HEIGHT,
    paddingTopBottom: PADDING_TOP_BOTTOM,
  }

  return <BaseRow {...props} />;
}

export default Row;
