import React from "react";
import backOfEnvelopeIcon from "../../../../../../../../assets/images/pdf-icon-back-of-envelope.png";
import { View, StyleSheet, Image, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    marginTop: 15,
  },
  titleContainer: {
    left: 46,
    height: 29,
  },
  titleIcon: {
    position: "absolute",
    width: 19,
    left: 0,
    top: 3,
  },
  titleSecondRow: {
    bottom: 0,
    left: 30,
    fontFamily: "Roboto Condensed Bold",
    fontSize: 13,
    letterSpacing: 0.25,
    color: "#333333",
  },
});

const Section = (props) => {
  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Image style={styles.titleIcon} src={backOfEnvelopeIcon} />
        <Text style={styles.titleSecondRow}>RETURN ANALYSIS</Text>
      </View>

      {props.children}
    </View>
  );
};

export default Section;
