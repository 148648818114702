import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import assumptionsIcon from "../../../../../../../assets/images/pdf-icon-assumptions.png";
import ConfigurationHandler from "../../../../../ConfigurationHandler";
import PdfContext from "../../../PdfContext";
import Logo from "../../sharedComponents/Logo/Logo";
import SectionTitle from "../../sharedComponents/SectionTitle";
import GroupHeaderRow from "../../sharedComponents/GroupHeaderRow";
import Row from "./Row";
import config from "./config";
import developmentAccessors from "../../../../../../../state/development/utils/developmentAccessors";
import { incomeProducingUsesIsEnabled } from "../../../../../../utils/uiToggleHelper";

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: "8px 38px 25px",
  },
  group: {
    marginTop: 5,
  },
});
const Assumptions = () => (
  <PdfContext.Consumer>
    {({ unitSystem, development }) => {
      const toggles = developmentAccessors.getBuildingUsageToggles(development);
      const shouldDisplayIncomeProducingUses = incomeProducingUsesIsEnabled(toggles);

      return (
        <View style={styles.container}>
          <View>
            <SectionTitle title={"ASSUMPTIONS"} titleIcon={assumptionsIcon} />

            <View style={styles.group}>
              <GroupHeaderRow text={"Property"} />
              <ConfigurationHandler config={config.parcelArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.existingStructureArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler
                config={config.parcelPurchasePricePerArea}
                component={Row}
                unitSystem={unitSystem}
              />
              <ConfigurationHandler config={config.parcelPurchasePrice} component={Row} unitSystem={unitSystem} />
            </View>

            <View style={styles.group}>
              <GroupHeaderRow text={"Building"} />
              <ConfigurationHandler config={config.floorAreaRatio} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler
                config={config.floorAreaRatioWithoutParking}
                component={Row}
                unitSystem={unitSystem}
              />
              <ConfigurationHandler config={config.unitsPerParcelArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.projectNumberOfFloors} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.buildingHeight} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.siteCoveragePercentage} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.siteCoverageArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.openSpaceArea} component={Row} unitSystem={unitSystem} />
            </View>

            <View style={styles.group}>
              <GroupHeaderRow text={"Areas"} />
              <ConfigurationHandler
                config={config.multifamilyTotalUnitQuantity}
                component={Row}
                unitSystem={unitSystem}
              />
              <ConfigurationHandler config={config.condoTotalUnitQuantity} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.retailNetLeasableArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.officeGrossLeasableArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.industrialNetLeasableArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.hotelRoomQuantity} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler
                config={config.parkingRequiredSpacesAfterReduction}
                component={Row}
                unitSystem={unitSystem}
              />
              <ConfigurationHandler config={config.parkingGrossBuildableArea} component={Row} unitSystem={unitSystem} />
            </View>

            {shouldDisplayIncomeProducingUses && (
              <View style={styles.group}>
                <GroupHeaderRow text={"Economics"} />
                <ConfigurationHandler
                  config={config.multifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableArea}
                  component={Row}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.multifamilyAverageYearOnePotentialGrossYearlyRentalIncomePerLeasableArea}
                  component={Row}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.retailYearOneRentPerArea}
                  component={Row}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.officeYearOneRentPerArea}
                  component={Row}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.industrialYearOneRentPerArea}
                  component={Row}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.hotelStabilizedAverageDailyRate}
                  component={Row}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.hotelStabilizedOccupancyPercentage}
                  component={Row}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.hotelStabilizedRevenuePerAvailableRoom}
                  component={Row}
                  unitSystem={unitSystem}
                />
                <ConfigurationHandler
                  config={config.hotelOtherRevenuePercentage}
                  component={Row}
                  unitSystem={unitSystem}
                />
              </View>
            )}

            <View style={styles.group}>
              <GroupHeaderRow text={"Project Cost"} />
              <ConfigurationHandler config={config.projectGrossBuildableArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler
                config={config.existingStructureDemolitionCost}
                component={Row}
                unitSystem={unitSystem}
              />
              <ConfigurationHandler config={config.projectHardCost} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.projectSoftCost} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.projectContingencyCost} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler
                config={config.projectConstructionCostIncludingDemolition}
                component={Row}
                unitSystem={unitSystem}
              />
              <ConfigurationHandler
                config={config.projectConstructionCostIncludingDemolitionPerBuildableArea}
                component={Row}
                unitSystem={unitSystem}
              />
              <ConfigurationHandler config={config.parcelPurchasePrice} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler
                config={config.projectTotalDevelopmentCost}
                component={Row}
                unitSystem={unitSystem}
              />
            </View>
          </View>
          <Logo />
        </View>
      );
    }}
  </PdfContext.Consumer>
);

export default Assumptions;
