import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    position: "relative",
    left: 0,
    height: 25,
    flex: 1,
  },
  text: {
    position: "absolute",
    top: 1,
    left: 10,
    fontFamily: "Roboto Bold",
    fontSize: 16,
    textTransform: "uppercase",
    letterSpacing: 0.25,
    color: "#ffffff",
  },
});

interface Props {
  pageTitle?: string;
}

const PageTitle = (props: Props) => (
  <View style={styles.container}>
    <Text style={styles.text}>{props.pageTitle}</Text>
  </View>
);

export default PageTitle;
